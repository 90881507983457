// @import "./colors.scss";

body {
  background-color: $secondary;
  height: 100%;
  width: 100%;
}

#main-menu {
  margin: 0;
  background-color: $primary;
}

.titlebarMT {
  background-color: $primary;
  color: $white;
  padding: 0.5rem;
}

.title-bar {
  background: $black;
  color: $white;
  padding: 0.5rem;
}

.menu-icon,
.title-bar-title {
  display: inline-block;
  vertical-align: middle;
}

.menu-icon {
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 16px;
}

#main-menu div {
  background-color: inherit;
}

.otherMenu {
  background-color: $otherMenu;
}

#menus-container {
  float: left;
}

#menucateg *,
.top-bar-right * {
  background-color: inherit;
}

#menucateg a {
  color: $secondary;
  padding: 0.3rem 1rem;
  background-color: $primary;
  margin: 0.3rem 0.3rem;
  border-radius: 4px;
}

#menucateg a::after {
  border-color: $white transparent transparent transparent;
  display: block;
  right: 0;
  /* margin: 0 0 0 5px; */
}

#menucateg ul li {
  flex-wrap: wrap;
}

/*******Tabs************/
.tabs {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: $primary;
}

.tabs-content {
  background-color: transparent;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.tabs-title > a:focus,
.tabs-title > a[aria-selected="true"] {
  background: $grey;
}

.tabs-title > a {
  font-size: 16px;
  font-weight: bold;
}

.menu-pages {
  /* margin-top: 10px; */
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  background-color: inherit;
  border: 1px solid $secondary;
  background-color: $primary !important;
}

.menu-pages > * {
  background-color: $primary !important;
  transition: all 0.4s ease-in;
  border-radius: 0 !important;
}

.menu-pages > *:hover {
  background-color: $accent-primary !important;
  transition: all 0.1s ease-in;
  border-radius: 0 !important;
}

.viewport-width {
  width: 100vw;
}

.no-menu-pages {
  display: none;
}

/*****************************/
.reveal {
  background-color: $secondary;
  border: 0.3rem solid $primary;
  border-radius: 10px;
}

input.ng-invalid,
input.ng-invalid-required,
select.ng-invalid,
select.ng-invalid-required,
textarea.ng-invalid,
textarea.ng-invalid-required {
  border-bottom: 0.13rem solid $primary;
}

/*****************/

/*******Loading panel*********/
#loadingpanel {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $secondary;
  z-index: 99;
  background-image: url("https://media.csoftonline.it/images/logos/carview_loading.gif");
  background-position: center;
  background-repeat: no-repeat;
}

#loadinglocalpanel {
  height: 100%;
  width: 100%;
  background-color: $secondary;
  z-index: 99;
  background-image: url("https://media.csoftonline.it/images/logos/carview_loading.gif");
  background-position: center;
  background-repeat: no-repeat;
}

#loadingpanelmap,
.loadingpanel {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  background-color: $secondary;
  z-index: 10;
  background-image: url("https://media.csoftonline.it/images/logos/carview_loading.gif");
  background-position: center;
  background-repeat: no-repeat;
}

.loadingpanel.solid {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  background-color: $secondary;
  z-index: 10;
  background-image: url("https://media.csoftonline.it/images/logos/carview_loading.gif");
  background-position: center;
  background-repeat: no-repeat;
}

/*****************************/

button.showButton {
  background-image: url("https://media.csoftonline.it/images/img/blue/arrowdown_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  background-position: center;
}

button.hideButton {
  background-image: url("https://media.csoftonline.it/images/img/blue/arrowup_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  background-position: center;
}

.dropdown-pane.searchFromMenu {
  background-color: $secondary !important;
  width: 15rem;
}

.switch-paddle.big {
  font-size: 1.2rem;
}

input:checked ~ .switch-paddle.big {
  padding-left: 0.5rem;
  text-align: left !important;
}

input ~ .switch-paddle.big {
  padding-right: 0.5rem;
  text-align: right;
}

input:checked ~ .switch-paddle.fillWidth::after {
  left: calc(100% - 1.7rem) !important;
}

input:checked ~ .switch-paddle.big.fillWidth::after {
  left: calc(100% - 1.8rem) !important;
}

.ar_border {
  /*border-radius: 10px;*/
  border: 2px solid $primary !important;
}

.ar_border.rounded {
  border-radius: 10px;
  /*border: 2px solid #e3923f;*/
}

.ar_border_thin {
  /*border-radius: 10px;*/
  border: solid $primary !important;
}

.panelcounter {
  float: left;
  padding: 0.3rem;
  background-color: $primary;
  color: $white;
  margin: 0 0.3rem 0.2rem 0;
  font-size: 0.85rem;
  line-height: 1rem;
  cursor: pointer;
}

.panelcounter.white {
  background-color: $white !important;
  color: $primary !important;
}

div.panelback span.text {
  padding: 0.3rem 0.8rem 0.3rem 0.5rem;
}

div.panelback span.sign {
  font-size: 2rem;
}

.white {
  background-color: $white !important;
}

.lightgray {
  background-color: #f2f2f2;
}

.lightergray {
  background-color: #f7f7f7;
}

.largeFontBold {
  font-size: large;
  font-weight: bold;
}

.ninetyperc {
  width: 90%;
}

.tenperc {
  width: 10%;
}

.fiveperc {
  width: 5%;
}

.smallPaddingTop {
  padding-top: 0.2rem !important;
}

.commento_trader {
  width: 70%;
  box-shadow: 3px 3px 5px #ddd;
  /*background: $secondary;*/
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin-bottom: 0.4rem;
}

.commento_trader .hour {
  display: block;
  font-size: 0.6rem;
  color: #838383;
  text-align: right;
  float: right;
  margin: 0.2rem 0.1rem auto 0.1rem;
}

.commento_trader .hour span {
  position: relative;
  bottom: -0.3rem;
}

.comments_chat div div {
  width: 30%;
  height: 100%;
  padding: 0.2rem 0.3rem 0 0;
  /* margin: 1rem 0.3rem 0.3rem 0; */
  display: inline-block;
  vertical-align: middle;
}

.comments_chat .date {
  width: 15% !important;
  margin: 0.2rem auto 0.5rem auto;
  background-color: #d4e9f4;
  text-align: center;
  font-size: 0.6rem;
  font-weight: bold;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
}

.comments_chat {
  height: 10rem;
  overflow-y: scroll;
  padding: 0.3rem 0 0.3rem 0;
  background-color: #f5f5f5;
}

.comments_chat .other {
  background-color: #eee;
}

.comments_chat .mine {
  background-color: #e1fdc7;
}

.attachmentShowList {
  padding: 0.3rem 0 0.2rem 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gap-1 {
  gap: 0.2rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.overflow {
  overflow-x: scroll;
}

.borderSolid {
  border-bottom: 0.1rem solid #ddd;
}

div.borderbottom {
  border-bottom: 0.1rem solid #ddd;
}

div.smallpadding {
  padding: 0.2rem;
}

div.spreadreducedpadding div.columns {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

/* legend per fieldset csoft edition*/

fieldset legend.csoft_dealer {
  background: none !important;
  width: 100%;
  height: 2rem;
  position: relative;
}

fieldset legend.csoft_dealer #backline {
  border-bottom: 1px solid #cacaca;
  height: 50%;
  width: 100%;
  position: absolute;
}

fieldset legend.csoft_dealer #frontpanel {
  width: 100%;
  position: absolute;
  z-index: 2;
}

fieldset legend.csoft_dealer #frontpanel .leftsection {
  background: $white;
  float: left;
}

fieldset legend.csoft_dealer #frontpanel .rightsection {
  float: right;
}

fieldset legend.csoft_dealer #frontpanel select {
  width: auto;
  height: inherit;
  padding: 0.3rem 1.5rem 0.3rem 0.8rem;
  font-size: inherit;
  margin-bottom: 0 !important;
}

/*************************************************************/

/*table raggruppamenti sedi dealer*/

#sedigroupedtable .header {
  background-color: #ddd;
  color: $primary;
  font-weight: bold;
}

#sedigroupedtable .body img.brand {
  /*width:1rem;*/
  height: 1.8rem;
  padding-left: 0.3rem;
}

#sedigroupedtable .body div.line:nth-child(even) {
  background-color: #f1f1f1;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

#sedigroupedtable .body div.line:nth-child(odd) {
  background-color: $white;
}

#sedigroupedtable .body div.line div.cell {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  height: 1.85rem;
}

#sedigroupedtable .body div.line div.buttoncell {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  text-align: center;
  height: 1.85rem;
}

#sedigroupedtable .body div.line div.buttoncell .button {
  width: 1rem;
  height: 1rem;
  margin-bottom: 0;
}

/*****pannellino a scomparsa per info utenze contatti*********/

.hvrlink {
  position: relative;
  display: inline-block;
  /*margin-bottom: 1rem;*/
}

/** hover styles **/
.hvrlink:hover .user-details-pane {
  display: block;
}

.user-details-pane {
  display: none;
  color: #414141;
  background: #dddddd;
  cursor: default;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 50;
  /*padding: 2px;*/
  text-align: left;
  border: 4px solid $primary;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  transition: box-shadow 0.2s ease-out;
}

.user-details-pane:hover {
  display: block;
}

.user-details-pane .insidepanel {
  font-size: 0.8rem;
}

.user-details-pane .insidepanel div:first-of-type {
  background-color: $primary;
  color: $white;
  text-align: center;
  margin-bottom: 0.3rem;
}

.user-details-pane .insidepanel span.sedeuser {
  font-weight: bold;
}

.user-details-pane .insidepanel span.userstatus {
  font-weight: bold;
}

.user-details-pane .insidepanel span.userstatus.locked {
  color: #9e0c0e;
}

.user-details-pane .insidepanel span.userstatus.active {
  color: #2e7547;
}

.user-details-pane .insidepanel span.userstatus.activenoapproved {
  color: #ff0000;
}

.user-details-pane .insidepanel span {
  white-space: nowrap;
  padding: 0 0.5rem;
  line-height: 1.3rem;
  display: block;
}

.user-details-pane .insidepanel button {
  width: 100%;
  margin: 0.3rem 0 0 0;
  border-radius: 0 !important;
}

/*************************************************************/

/*******Tabs************/
.tabs {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: #004;
}

.tabs-content {
  background-color: transparent;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.tabs-title > a:focus,
.tabs-title > a[aria-selected="true"] {
  background: #dddddd;
}

.tabs-title > a {
  font-size: 16px;
  font-weight: bold;
}

/*****************************/
.reveal {
  background-color: #e9e9e9;
  border: 0.3rem solid #004;
  border-radius: 10px;
}

.is-invalid-input:not(:focus) {
  background-color: rgba(198, 15, 19, 0.1);
  border-color: #c60f13;
}

.form-error.is-visible {
  display: block;
}
.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
}
.form-error,
.is-invalid-label {
  color: #c60f13;
}
.form-error,
.menu-text,
.switch {
  font-weight: 700;
}

mat-calendar table,
mat-calendar table > thead,
mat-calendar table > thead tr,
mat-calendar table > thead tr th,
mat-calendar table > tbody,
mat-calendar table > tbody tr,
mat-calendar table > tbody tr td {
  margin: 0 !important;
  border: none !important;
}
mat-calendar table tbody tr {
  background-color: white !important;
}
mat-calendar table thead {
  background-color: $primary !important;
  color: $white !important;
  padding: 1rem 0 !important;
}
