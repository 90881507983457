// @import "./colors.scss";

.mdc-tab {
  position: relative !important;
}

.mdc-tab:hover {
  background-color: #fff;
}

.mdc-tab--active {
  background-color: darken($color: #e0e0e0, $amount: 1) !important;
}

.mat-mdc-tab-link {
  padding: 1rem 0 !important;
  font-weight: bolder !important;
  color: $primary !important;
}

.mat-mdc-tab {
  padding: 1rem 0 !important;
  font-weight: bolder !important;
  color: $primary !important;
}

.mat-mdc-tab-link-container {
  border-bottom: 1px solid $border-button !important;
}
