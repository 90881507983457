// @import "./colors.scss";
// @import "./select.scss";
// @import "./inputs.scss";

textarea {
  min-height: 50px;
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input:disabled,
textarea:disabled,
textarea[readonly] {
  // filter: opacity(30%);
  background-color: $secondary;
  cursor: not-allowed !important;
  pointer-events: none;
}

input[readonly] {
  background-color: $secondary;
  pointer-events: none;
}

[type="submit"],
[type="button"] {
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  box-sizing: border-box;
}

[type="file"],
[type="checkbox"],
[type="radio"] {
  margin: 0 0 1rem;
}

[type="checkbox"] + label,
[type="radio"] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline;
}

label > [type="checkbox"],
label > [type="label"] {
  margin-right: 0.5rem;
}

[type="file"] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.8;
  color: #0a0a0a;
}

.form-error,
.menu-text,
.switch {
  font-weight: 700;
}

label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #333;
}

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
}

.input-group-button a,
.input-group-button button,
.input-group-button input,
fieldset {
  margin: 0;
}

.input-group > :first-child {
  border-radius: 3px 0 0 3px;
}

// .input-group:last-child>* {
//     border-radius: 0 3px 3px 0
// }

.input-group-button,
.input-group-field,
.input-group-label {
  display: table-cell;
  margin: 0;
  vertical-align: middle;
}

.input-group-label {
  width: 1%;
  height: 100%;
  padding: 0 1rem;
  background: #e6e6e6;
  color: #0a0a0a;
  border: 1px solid #cacaca;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  height: 2.5rem;
}

.input-group-button {
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
}

fieldset {
  border: 0;
  padding: 0;
}

legend {
  margin-bottom: 0.5rem;
}

.fieldset {
  padding: 1.25rem;
  margin: 1.125rem 0;
}

.fieldset legend {
  background: #fefefe;
  padding: 0 0.1875rem;
  margin: 0 0 0 -0.1875rem;
  border: 1px solid $secondary;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
}

.fieldset {
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.alert-light {
  background-color: $alert-background !important;
}

.success {
  background-color: $green !important;
}

.alert {
  background-color: $alert-accented !important;
}

.label.success, .label.alert{
  color: #fff;
}

.callout {
  margin: 0 0 1rem;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 3px;
  position: relative;
  color: $black;
  background-color: $white;
}

.badge,
.button,
.input-group-label,
.menu.icon-top > li > a,
.orbit-bullets {
  text-align: center;
}

.disabled:not(.csoft-slider) {
  background-color: $secondary !important;
  cursor: not-allowed !important;
  pointer-events: none;
}
