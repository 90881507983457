.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: rgb(0 0 0);
  opacity: 0.5;
}

.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    $black 55%,
    rgba(0, 0, 0, (1 - 0.2)) 75%,
    $black 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
